<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(item, i) in item.content" :key="i">
                    <a class="router-link-active"
                      >{{ item.title }}
                      <span> - {{ item.name }}</span></a
                    >
                    {{ item.desc }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Skills",
          },
          {
            id: 2,
            name: "Tools",
          },
          {
            id: 3,
            name: "Experience",
          },
          {
            id: 4,
            name: "Philosophy",
          },
        ],

        tabContent: [
          {
            id: 1,
            content: [
              {
                title: "Problem Solving",
                name: "Expert at tackling modern, complex challenges using cutting-edge languages and frameworks to deliver effective solutions.",
                desc: "",
              },
              {
                title: "Vision & Design",
                name: "Crafting visually stunning and intuitive UX/UI designs with meticulous attention to detail and precision.",
                desc: "",
              },
              {
                title: "Project Management",
                name: "Every project is driven by top-notch organization, time management, and seamless communication to ensure success.",
                desc: "",
              },
              {
                title: "Mathematical Aptitude",
                name: "Strong analytical and mathematical skills, paired with a quick learning curve, enable me to solve problems efficiently and effectively.",
                desc: "",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                title: "Frameworks",
                name: "Web & App Development",
                desc: "Vue.js, Laravel, Express.js, CakePHP, Bootstrap, Angular, React",
              },
              {
                title: "Databases",
                name: "Data Storage & Analytics",
                desc: "MySQL, PostgreSQL, MongoDB, MariaDB, & More",
              },
              {
                title: "Design",
                name: "UX/UI & Branding",
                desc: "HTML & CSS, Affinity Suite, Adobe Suite, Sketch",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                title: "Freelance Projects",
                name: "Delivered tailored web solutions to clients across industries, ensuring functionality, aesthetics, and client satisfaction.",
                desc: "",
              },
              {
                title: "Agency Collaboration",
                name: "Worked with creative teams to develop seamless web applications and design systems, bridging the gap between vision and execution.",
                desc: "",
              },
              {
                title: "End-to-End Development",
                name: "Managed projects from concept to completion, including planning, design, development, testing, and deployment.",
                desc: "",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                title: "Innovation-Driven",
                name: "I believe in pushing boundaries and leveraging the latest technologies to create cutting-edge solutions that stand out.",
                desc: "",
              },
              {
                title: "Client-Centered",
                name: "Every project starts with understanding your goals and vision. Collaboration and communication are at the heart of my process.",
                desc: "",
              },
              {
                title: "Quality Over Quantity",
                name: "I prioritize delivering exceptional results, ensuring every detail is perfect and meets the highest standards.",
                desc: "",
              },
              {
                title: "Adaptability",
                name: "The tech world evolves rapidly, and I thrive on staying ahead of trends, learning new tools, and adapting to challenges.",
                desc: "",
              },
            ],
          },
        ],
      };
    },
  };
</script>
